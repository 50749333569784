<template>
  <div class="Zhuanjia">
    <ul class="zhuanjiatype_big">
      <li>专业分类</li>
      <li @click="typebigclick('')">全部</li>
      <li
        v-for="(item, index) in type_big"
        :key="index"
        @click="typebigclick(item.num)"
      >
        {{ item.value }}
      </li>
    </ul>
    <ul class="Zhuanjiaul">
      <li
        class="Zhuanjia_li"
        v-for="(item, index) in Zhuanjiaul"
        :key="item.id"
        :class="item.title ? '' : 'Zhuanjia_lizero'"
        @mouseenter="Zhuanjia_lienter(index)"
        @mouseleave="Zhuanjia_lileave(index)"
        @click="Zhuanjia_litop_ttn(item.id)"
      >
        <img class="Zhuanjia_liimg" v-lazy="item.titlepic" alt="" />
        <div class="Zhuanjia_libottom" v-show="item.Zhuanjia_libottom">
          <div class="Zhuanjia_libottom_l">
            <p>{{ item.title }}</p>
            <p>
              {{
                item.exptype == 22
                  ? "环境设计"
                  : item.exptype == 23
                  ? "工业产品设计"
                  : "视觉传达设计"
              }}
            </p>
          </div>
          <div class="Zhuanjia_libottom_r">
            <p v-html="item.fbtitle"></p>
            <!-- <p v-html="item.cover_text"></p>  -->
          </div>
        </div>
        <div class="Zhuanjia_litop" v-show="item.Zhuanjia_litop">
          <div class="Zhuanjia_litop_name">
            <p>{{ item.title }}</p>
            <p>
              {{
                item.exptype == 22
                  ? "环境设计"
                  : item.exptype == 23
                  ? "工业产品设计"
                  : "视觉传达设计"
              }}
            </p>
          </div>
          <div class="Zhuanjia_litop_text" v-html="item.fbtitle"></div>
          <div class="Zhuanjia_litop_ttn" @click="Zhuanjia_litop_ttn(item.id)">
            查看详情
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".zhuanjiatype_big li", function () {
  $(this).css("color", "#4FC6FF");
  $(this).siblings().css("color", "#666");
});
export default {
  components: {},
  data() {
    return {
      userinfo: {},
      Zhuanjiaul: [],
      type_big: [
        {
          value: "环境设计",
          num: "22",
        },
        {
          value: "工业产品设计",
          num: "23",
        },
        {
          value: "视觉传达设计",
          num: "24",
        },
      ],
    };
  },
  created() {
    this.fenxiang(
      "和作营-专家库",
      "专业大咖指导，用心、倾心、多领域专家资源的跨界与融合。",
      "https://mmbiz.qpic.cn/mmbiz_png/vBjAjicW4DmC6McbmE18LUoeX3ibDAIiawYO72fKWibdSgaqfus1j5WxJR0SN7nOgjNFjGsmk3jPqecG1rqOsUxTzg/0?wx_fmt=png"
    );
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq("");
  },
  methods: {
    axiosqq(exptype) {
      var that = this;
      this.$axios({
        url: "/api/getExperts",
        method: "post",
        data: {
          page: "1",
          limit: "20",
          exptype: exptype,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          for (var i = 0; i < res.data.result.list.length; i++) {
            res.data.result.list[i]["Zhuanjia_libottom"] = true;
            res.data.result.list[i]["Zhuanjia_litop"] = false;
          }
          that.Zhuanjiaul = res.data.result.list;
          var count = res.data.result.count % 3;
          if (count == 2) {
            that.Zhuanjiaul.push("");
          }
        } else {
          console.log("请求失败");
        }
      });
    },
    Zhuanjia_lienter(i) {
      this.Zhuanjiaul[i].Zhuanjia_libottom = false;
      this.Zhuanjiaul[i].Zhuanjia_litop = true;
    },
    Zhuanjia_lileave(i) {
      this.Zhuanjiaul[i].Zhuanjia_libottom = true;
      this.Zhuanjiaul[i].Zhuanjia_litop = false;
    },
    Zhuanjia_litop_ttn(id) {
      this.$router.push({ path: "/Zhuanjia_c", query: { data: id } });
    },
    typebigclick(id) {
      this.axiosqq(id);
    },
  },
};
</script>
<style>
@import "../assets/css/zhuanjia.css";
</style>
